<template>
  <div
    class="mt-2 col-12"
    align-h="center"
  >
    <div>
      <b-row class="home-page">
        <b-table
          id="tableRoles"
          ref="table-roles"
          refreshed
          responsive
          selectable
          noCollapse
          no-border-collapse
          show-empty
          hover
          :current-page="currentPage"
          :select-mode="selectMode"
          :items="getRolesItems"
          :fields="fields"
          thead-class="colorHead"
          tbody-class="colorBody"
        >
          <template #head()="data">
            <span class="colorHead">{{ data.label.toUpperCase() }}</span>
          </template>
          <template #cell()="data">
            <span class="colorHead">{{ data.value | filterNullValue }}</span>
          </template>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template
            slot="row-details"
            slot-scope="scope"
          >
            {{ scope.item.detail }}
          </template>
        </b-table>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BTr,
  BTh,
  BPagination,
  BButton,
  BRow,
  BCol,
  BSpinner,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormSelect,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BImg,
} from 'bootstrap-vue'
import store from '@/store'
import utilsAiops from '@/@core/utils/aiops/utils'

const {
  loading,
  loadingProccessSpinner,
} = utilsAiops()

export default {
  components: {
    BTable,
    BTr,
    BTh,
    BPagination,
    BButton,
    BRow,
    BCol,
    BSpinner,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BImg,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          class: 'pl-3',
        },
        {
          key: 'users',
          label: 'Users',
          sortable: true,
          class: 'pl-3',
        },
      ],
      rolesData: [],
      selectMode: 'single',
    }
  },
  computed: {
    getRolesItems() {
      return this.rolesData
    },
  },
  created() {
    if (!this.$ability.can('aiopsuser')) {
      this.$router.push({ name: 'aiops-home' })
    }
    this.welcomeAssist()
    this.getRoles()
  },
  methods: {
    getMembersGroups(id) {
      loadingProccessSpinner(true)
      store.dispatch('userRolesAiops/getMembersGroups', id)
        .then(response => {
          const listMembers = response
          const numberMembers = listMembers.length
          this.rolesData.forEach(role => {
            const newRoleData = role
            if (id === role.id) {
              newRoleData.users = numberMembers
            }
          })
          loadingProccessSpinner(false)
        })
        .catch(() => {
          this.errorMessage('Unable to obtain the number of members for each group')
          loadingProccessSpinner(false)
        })
    },
    getRoles() {
      store.dispatch('userRolesAiops/getGroups')
        .then(response => {
          loading(false)
          this.groupList = response
          const items = this.groupList.map(group => ({ id: group.id, name: group.name, users: '-' }))
          this.rolesData = items
          this.groupList.map(group => this.getMembersGroups(group.id))
        })
        .catch(() => {
          this.errorMessage('Unable to obtain the list of groups')
          loading(false)
        })
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', false)
    },
    errorMessage(message = 'Something was wrong') {
      const variant = 'warning'
      this.$bvToast.toast(message, {
        title: 'Classification correction',
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .backGroundHead {
    background-color: #2f2b6d;
  }

::v-deep {

  .b-table-sticky-header > .table.b-table > tbody.colorBody > tr > td {
    padding: 0.72rem 5px !important;
  }

  .colorHead {
    background-color: #eef7f9;
    color: #005573;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  .colorBody {
    background-color: #fff;
    color: #6f9ca8;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
    overflow-y: scroll !important;
    padding: 0.72rem 5px !important;
  }

  b-pagination .page-item .active .page-link {
    border-radius: 5rem;
    background-color: #12a71e !important;
  }

  .table tbody tr {
    height: 55px !important;
  }
}

.pagination {
  ::v-deep {
    .page-item.active {
      background-color: transparent;
      border-radius: 0;
    }
    .page-item.active .page-link {
      background: #ea5455 !important;
    }
    .page-link {
      background-color: transparent !important;
      color: #6f9ca8;
      font-weight: 200;
    }
    .page-link:hover {
      background-color: transparent !important;
      color: #6f9ca8;
    }
  }
}

.aiops_model_table_col_action {
  text-align: right;
}

.aiops__roles-role-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
}
</style>
